<script>

/**
 * Monthly-Earning component
 */
export default {
  components: {
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 200,
          type: "radialBar",
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: undefined,
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: undefined,
                formatter: function (val) {
                  return Math.round(val * 10) / 10 + "%";
                },
              },
            },
          },
        },
        colors: ["#556ee6"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["접속비율"],
      },
    };
  },
  props: {
    total: {
      type: Number,
      default : 0
    },
    data: {
      type: Object,
      default() {
        return {
          curr: 0,
          prev: 0,
        }
      }
    },
  },
  computed: {
    chartSeries() {
      if(this.total === 0) return [0]
      return [this.data.curr / this.total * 100]
    },
    connectCount() {
      return this.data.curr.toLocaleString('ko-KR') + '명'
    },
    connectPrevCount() {
      return this.data.prev.toLocaleString('ko-KR') + '명'
    },
    connectRate() {
      return this.calcRate(this.data.curr, this.data.prev)
    },
    connectIcon() {
      if(this.connectRate > 0) return 'text-success'
      else if(this.connectRate < 0) return 'text-danger'
      return 'text-default'
    },
    connectIcon2() {
      if(this.connectRate > 0) return 'mdi-chevron-up'
      else if(this.connectRate < 0) return 'mdi-chevron-down'
      return 'mdi-minus'
    }
  },
  mounted() {
  },
  methods: {
    calcRate(first, next) {
      if(first === next) return 0

      let base = Math.max(first, next)
      if(base === 0) return 0

      let sub = Math.min(first, next)
      let calc = (base - sub) / base * 100
      if(first < next) calc = calc * -1
      return Math.round(calc * 10) / 10
    },
  }
};
</script>

<template>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">
          접속비율
        </h4>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-muted mt-4 mb-4">실시간현황(5시간)</p>
            <h4>{{connectCount}} 접속중</h4>
            <p class="text-muted">
              <span class="me-2" :class="`${connectIcon} `">
                {{connectRate}}%
                <i class="mdi " :class="`${connectIcon2} `"></i>
              </span>
              <span class="font-size-11">1시간전({{connectPrevCount}}) 대비</span>
            </p>

            <p class="text-muted mb-0">API 연결 회원수</p>
          </div>
          <div class="col-sm-6">
            <!-- Chart -->
            <apexchart
              class="apex-charts"
              type="radialBar"
              height="225"
              dir="ltr"
              :series="chartSeries"
              :options="chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
</template>