<script>
import Layout from "../../layouts/main";

import {apiStatisticsMethods} from "@/state/helpers";
import UserConnect from "@/components/widgets/user-connect";
import UserConnect2 from "@/components/widgets/user-connect-2";
import UserChart from "@/components/widgets/user-chart";
import store from "@/state/store";

import gsap from 'gsap'
import {numberFormat} from "@/helpers/helpers";

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    UserConnect,
    UserConnect2,
    UserChart
  },
  data() {
    return {
      menuId: 100100,
      menuCode: "default",
      osChart: {
        series: [0, 0, 0],
        chartOptions: {
          labels: ["안드로이드", "애플", "기타"],
          colors: ['#556ee6', '#34c38f', '#f46a6a'],
          plotOptions: {
            pie: {
              donut: {
                size: '70%'
              }
            }
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0
          },
          responsive: [{
            breakpoint: 600,
            options: {
              chart: {
                height: 240
              },
              legend: {
                show: false
              },
            }
          }]
        }
      },
      columnDatalabelChart : {
        series: [{
          name: '가입자수',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            }
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            offsetX: 20,
          },
          colors: ['rgba(52, 195, 143, 0.85)'],
          grid: {
            borderColor: '#f1f1f1',
          },
          xaxis: {
            categories: ["서울", "부산", "대구", "인천", "광주", "대전", "울산", "세종", "경기", "충북", "충남", "전남"],
            position: 'left',
            labels: {
              show: false
            }
          },
          yaxis: {
            labels: {
              show: true
            }
          },
        }
      },

      statisticsData: [],

      dConnectHour5Count: 0,
      connectHour5Count: 0,

      connectHour5Data: {
        curr: 0,
        prev: 0,
      },

      connectData: {
        minute5: 0,
        hour5: 0,
        etc: 0,
      },

      dSigninTodayCount: 0,
      dSigninTodayPrevCount: 0,
      signinTodayCount: 0,
      signinTodayPrevCount: 0,
      signinTodayRate: 0,
      signinTodayIcon: 'mdi-minus',
      signinTodayIcon2: 'badge-soft-warning',

      dSigninMonthCount: 0,
      dSigninMonthPrevCount: 0,
      signinMonthCount: 0,
      signinMonthPrevCount: 0,
      signinMonthRate: 0,
      signinMonthIcon: 'mdi-minus',
      signinMonthIcon2: 'badge-soft-warning',

      dUserTotalCount: 0,
      userTotalCount: 0,

      timer: null
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
    document.title = "도와줘 관리자";
  },
  mounted() {
    this.loadData()

    this.timer = setInterval(() => {
      this.loadData()
    }, 1000 * 60)
  },
  unmounted() {
    clearInterval(this.timer)
  },
  methods: {
    ...apiStatisticsMethods,
    loadData() {
      // alert(1);
      store.dispatch("apiStatistics/dataAll").then((result) => {
        // console.log(result)
        this.updateData(result.data)
      })
    },
    calcRate(first, next) {
      if(first === next) return 0

      let base = Math.max(first, next)
      if(base === 0) return 0

      let sub = Math.min(first, next)
      let calc = (base - sub) / base * 100
      if(first < next) calc = calc * -1
      return Math.round(calc * 10) / 10
    },
    updateData(data) {
      this.statisticsData = data

      if(!data.total) return
      if(!data.total.count) return

      this.userTotalCount = data.total.count

      this.signinTodayCount = data.signin.today.curr
      this.signinTodayPrevCount = data.signin.today.prev

      this.signinTodayRate = this.calcRate(data.signin.today.curr, data.signin.today.prev)
      if(this.signinTodayRate > 0) {
        this.signinTodayIcon = 'mdi-chevron-up text-success'
        this.signinTodayIcon2 = 'badge-soft-success'
      }
      else if(this.signinTodayRate < 0) {
        this.signinTodayIcon = 'mdi-chevron-down text-danger'
        this.signinTodayIcon2 = 'badge-soft-danger'
      }
      else {
        this.signinTodayIcon = 'mdi-minus'
        this.signinTodayIcon2 = 'badge-soft-warning'
      }

      this.signinMonthCount = data.signin.month.curr
      this.signinMonthPrevCount = data.signin.month.prev
      this.signinMonthRate = this.calcRate(data.signin.month.curr, data.signin.month.prev)
      if(this.signinMonthRate > 0) {
        this.signinMonthIcon = 'mdi-chevron-up text-success'
        this.signinMonthIcon2 = 'badge-soft-success'
      }
      else if(this.signinMonthRate < 0) {
        this.signinMonthIcon = 'mdi-chevron-down text-danger'
        this.signinMonthIcon2 = 'badge-soft-danger'
      }
      else {
        this.signinMonthIcon = 'mdi-minus'
        this.signinMonthIcon2 = 'badge-soft-warning'
      }

      // 지역별 가입자
      let categories = [];
      let chartData = [];
      data.area.forEach(row => {
        categories.push(row.name)
        chartData.push(row.count)
      });

      this.$refs.apexchartArea.updateOptions({
        xaxis: {
          categories : categories
        }
      })
      this.$refs.apexchartArea.updateSeries([{
        name: '가입자수',
        data: chartData
      }])

      this.connectHour5Count = data.connect.hour5[0].count

      this.connectHour5Data = {
        curr: data.connect.hour5[0].count,
        prev: data.connect.hour5[1].count,
      }

      // device
      let series = this.osChart.series
      data.device.forEach(row => {
        if(row.code === 'Android') series[0] = row.count
        else if(row.code === 'iOS') series[1] = row.count
        else if(row.code === 'Etc') series[2] = row.count
      });
      this.osChart.series = series

      this.connectData = {
        minute5: 0,
        hour5: this.connectHour5Data.curr,
        etc: data.connect.hourEtc[0].count,
      }
      // console.log(data)
    }
  },
  computed: {
    d2SigninTodayCount() {
      return numberFormat(this.dSigninTodayCount)
    },
    d2SigninTodayPrevCount() {
      return numberFormat(this.dSigninTodayPrevCount)
    },
    d2ConnectHour5Count() {
      return numberFormat(this.dConnectHour5Count)
    },
    d2SigninMonthCount() {
      return numberFormat(this.dSigninMonthCount)
    },
    d2SigninMonthPrevCount() {
      return numberFormat(this.dSigninMonthPrevCount)
    },
    d2UserTotalCount() {
      return numberFormat(this.dUserTotalCount)
    }
  },
  watch: {
    userTotalCount(n) {
      gsap.to(this, { duration: 0.5, dUserTotalCount: Number(n) || 0 })
    },
    signinTodayCount(n) {
      gsap.to(this, { duration: 0.5, dSigninTodayCount: Number(n) || 0 })
    },
    signinTodayPrevCount(n) {
      gsap.to(this, { duration: 0.5, dSigninTodayPrevCount: Number(n) || 0 })
    },
    signinMonthCount(n) {
      gsap.to(this, { duration: 0.5, dSigninMonthCount: Number(n) || 0 })
    },
    signinMonthPrevCount(n) {
      gsap.to(this, { duration: 0.5, dSigninMonthPrevCount: Number(n) || 0 })
    },
    connectHour5Count(n) {
      gsap.to(this, { duration: 0.5, dConnectHour5Count: Number(n) || 0 })
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-4">
        <div class="card bg-soft bg-primary">
          <div>
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-3">
                  <h5 class="text-primary">회원통계</h5>
                  <p>실시간 현황</p>

                  <ul class="ps-3 mb-0">
                    <li class="py-1">회원수 : {{d2UserTotalCount}}명</li>
                    <li class="py-1">접속수 : {{d2ConnectHour5Count}}명</li>
                  </ul>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                    src="@/assets/images/profile-img.png"
                    alt
                    class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                        class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-primary
                        text-primary
                        font-size-18
                      "
                    >
                      <i class="bx bx-user"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">오늘 가입자</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{d2SigninTodayCount}}명
                    <i class="mdi ms-1" :class="`${signinTodayIcon} `"></i>
                  </h4>
                  <div class="d-flex">
                    <span class="badge font-size-12" :class="`${signinTodayIcon2} `">{{signinTodayRate}}%</span>
                    <span class="ms-2 text-truncate font-size-11">전날({{d2SigninTodayPrevCount}}명) 대비</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                        class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-primary
                        text-primary
                        font-size-18
                      "
                    >
                      <i class="bx bx-user"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">이달 가입자</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    {{d2SigninMonthCount}}명
                    <i class="mdi ms-1" :class="`${signinMonthIcon} `"></i>
                  </h4>
                  <div class="d-flex">
                    <span class="badge font-size-12" :class="`${signinMonthIcon2} `">{{signinMonthRate}}%</span>
                    <span class="ms-2 text-truncate font-size-11">전달({{d2SigninMonthPrevCount}}명) 대비</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- end row -->
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5">
        <UserConnect :data="connectHour5Data" :total="userTotalCount" />
      </div>
      <div class="col-sm-6 col-md-6 col-xl-4">

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">OS 접속비율(접속 24시간 이내)</h4>
            <!-- Donut Chart -->
            <apexchart
                class="apex-charts"
                height="220"
                type="donut"
                dir="ltr"
                :series="osChart.series"
                :options="osChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-xl-3">

        <UserConnect2 :data="connectData" />
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12">

        <div class="card">

          <UserChart />
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">지역별 통계(접속 24시간 이내)</h4>
            <!-- Column with Data Labels -->
            <apexchart
                ref="apexchartArea"
                class="apex-charts"
                height="350"
                type="bar"
                dir="ltr"
                :series="columnDatalabelChart.series"
                :options="columnDatalabelChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style scoped>
.center-cropped {
  width:100%;
  height:50px;
  object-fit: cover;
}
</style>

