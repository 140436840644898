<script>
import store from "@/state/store";

export default {
  data() {
    return {
      chartData: {
        series: [
          {
            name: "현재",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: "과거",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          colors: ["#556ee6", "#f1b44c"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },
          xaxis: {
            categories: ["1","2","3","4","5","6","7","8","9","10","11","12"],
          },
          markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
              size: 4,
              sizeOffset: 2,
            },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
          },
        },
      },
      selection: "all",
    };
  },
  methods: {
    dataUserSignin(type) {
      this.selection = type
      // alert(1);
      store.dispatch("apiStatistics/dataUserSignin", { type }).then((result) => {
        // console.log(result)
        let categories = []
        let curr = []
        let prev = []
        let series = []
        if(type === 'day') {
          result.data.curr.forEach(row => {
            categories.push(row.day)
            curr.push(row.count)
          });
          result.data.prev.forEach(row => {
            prev.push(row.count)
          });
          curr = curr.slice(0, categories.length)
          prev = prev.slice(0, categories.length)
          series = [
            {
              name: "이번달",
              data: curr,
            },
            {
              name: "한달전",
              data: prev,
            },
          ];
        }
        else if(type === 'month') {
          console.log(result.data)
          result.data.curr.forEach(row => {
            categories.push(row.month)
            curr.push(row.count)
          });
          result.data.prev.forEach(row => {
            prev.push(row.count)
          });
          curr = curr.slice(0, categories.length)
          prev = prev.slice(0, categories.length)
          series = [
            {
              name: "올해",
              data: curr,
            },
            {
              name: "작년",
              data: prev,
            },
          ];
        }
        else if(type === 'year') {
          result.data.curr.forEach(row => {
            categories.push(row.year)
            curr.push(row.count)
          });
          result.data.prev.forEach(row => {
            prev.push(row.count)
          });
          curr = curr.slice(0, categories.length)
          prev = prev.slice(0, categories.length)
          series = [
            {
              name: "년도",
              data: curr,
            },
            {
              name: "5년전",
              data: prev,
            },
          ];
        }
        // console.log(series);
        this.$refs.apexchart.updateOptions({
          xaxis: {
            categories : categories
          }
        })
        this.$refs.apexchart.updateSeries(series)
      })
    },
  },
  mounted() {
    this.dataUserSignin('day')
  }
};
</script>

<template>
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <h5 class="card-title me-2">가입자 통계</h5>
      <div class="ms-auto">
        <div class="toolbar button-items text-end">
          <button
            type="button"
            class="btn btn-light btn-sm"
            @click="dataUserSignin('day')"
            :class="{ active: selection === 'day' }"
          >
            일별
          </button>
          <button
            type="button"
            class="btn btn-light btn-sm"
            @click="dataUserSignin('month')"
            :class="{ active: selection === 'month' }"
          >
            월별
          </button>
          <button
            type="button"
            class="btn btn-light btn-sm"
            @click="dataUserSignin('year')"
            :class="{ active: selection === 'year' }"
          >
            년도
          </button>
        </div>
      </div>
    </div>


    <hr class="mb-4" />

    <apexchart
      ref="apexchart"
      class="apex-charts"
      dir="ltr"
      height="350"
      :series="chartData.series"
      :options="chartData.chartoptions"
    ></apexchart>
  </div>
</template>
