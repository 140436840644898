<script>

import gsap from "gsap";
import {numberFormat} from "@/helpers/helpers";

export default {
  components: {
  },
  props: {
    updating: {
      type: Boolean,
    },
    data: {
      type: Object,
      default() {
        return {
          minute5: 0,
          hour5: 0,
          etc: 0,
        }
      }
    },
  },
  computed: {
    connectMinute5Rate() {
      let total = this.data.hour5 + this.data.etc
      if(total === 0) return 0
      return Math.round((this.data.minute5 / total * 100) * 10) / 10
    },
    connectHour5Rate() {
      let total = this.data.hour5 + this.data.etc
      if(total === 0) return 0
      return Math.round(((this.data.hour5 - this.data.minute5) / total * 100) * 10) / 10
    },
    disconnectRate() {
      let total = this.data.hour5 + this.data.etc
      if(total === 0) return 0
      return Math.round((this.data.etc / total * 100) * 10) / 10
    },
    d2ConnectMinute5Count() {
      return numberFormat(this.dConnectMinute5Count)
    },
    d2ConnectHour5Count() {
      return numberFormat(this.dConnectHour5Count - this.dConnectMinute5Count)
    },
    d2DisconnectCount() {
      return numberFormat(this.dDisconnectCount)
    }
  },
  data() {
    return {
      dConnectMinute5Count: 0,
      dConnectHour5Count: 0,
      dDisconnectCount: 0,

      connects: [
        {
          name: "접속중",
          text: "Connect",
          sales: 37,
          color: "#556ee6",
        },
        {
          name: "연결종료",
          text: "Disconnect",
          sales: 72,
          color: "#34c38f",
        },
        {
          name: "사용안함",
          text: "Not",
          sales: 54,
          color: "#f46a6a",
        },
      ],
    };
  },
  watch: {
    'data.minute5'(n) {
      gsap.to(this, { duration: 0.5, dConnectMinute5Count: Number(n) || 0 })
    },
    'data.hour5'(n) {
      gsap.to(this, { duration: 0.5, dConnectHour5Count: Number(n) || 0 })
    },
    'data.etc'(n) {
      gsap.to(this, { duration: 0.5, dDisconnectCount: Number(n) || 0 })
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="clearfix">

        <h4 class="card-title">접속현황</h4>
      </div>
      <div>
        <div>
          <table class="table align-middle mb-0">
            <tbody>
              <tr>
                <td>
                  <h5 class="font-size-14 mb-1">접속중(5시간)</h5>
                  <p class="text-muted mb-0">{{ d2ConnectHour5Count }}</p>
                </td>
                <td class="text-end">
                  <p class="text-muted mb-1">비율</p>
                  <h5 class="mb-0">{{connectHour5Rate}}%</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="font-size-14 mb-1">연결종료</h5>
                  <p class="text-muted mb-0">{{ d2DisconnectCount }}</p>
                </td>
                <td class="text-end">
                  <p class="text-muted mb-1">비율</p>
                  <h5 class="mb-0">{{disconnectRate}}%</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="font-size-14 mb-1">&nbsp;</h5>
                  <p class="text-muted mb-0">&nbsp;</p>
                </td>
                <td class="text-end">
                  <p class="text-muted mb-1">&nbsp;</p>
                  <h5 class="mb-0">&nbsp;</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
